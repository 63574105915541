import React from 'react';
import { Cushion, Grid, LinearGradient, Rectangle, Shadow, Typography } from '@pitchero/react-ui';
import Icon from '../icon';
import { Trans } from '../../lib/i18n';

const CompleteOfficialContact = () => (
  <Grid>
    <LinearGradient
      component="div"
      angle={0}
      stops={[
        [0, 'white'],
        ['50%', 'white'],
        ['50%', 'primary'],
      ]}
      style={{ textAlign: 'center' }}
    >
      <Shadow shadow="dark">
        <Rectangle
          fill="positive"
          radius="circle"
          style={{
            display: 'inline-block',
            height: '66px',
            width: '66px',
            zIndex: 999,
          }}
          component="div"
        >
          <Cushion all={18}>
            <Icon name="tick" color="white" size={30} />
          </Cushion>
        </Rectangle>
      </Shadow>
    </LinearGradient>

    <Cushion top="medium">
      <Typography
        preset="subtitle--large"
        align="center"
        component="div"
        color="dustygrey"
        style={{ textTransform: 'none' }}
      >
        <Trans i18key="common:official_contact_sent">Your message has been sent</Trans>
      </Typography>
    </Cushion>
  </Grid>
);

export default CompleteOfficialContact;
